/** @jsx jsx */
import { jsx } from "theme-ui";
import {
  setUser,
  setUserToken,
  showAlert,
} from "gatsby-plugin-hfn-profile/state/actions";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSrcmAPI,
  fetchSrcmProfileMe,
} from "sites-common/service-wrappers/profile-api";
import { useLocation } from "@reach/router";
import { fget } from "gatsby-plugin-hfn-profile/components/Login";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import { useFirebase } from "react-redux-firebase";
import { selectorGlobalConfig } from "gatsby-plugin-hfn-profile/state/selectors";

const OneAuthPage = () => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const location = useLocation();
  const apiCallCount = useRef(0);
  const { srcmProfile } = useAuth();
  const isBrowser = () => typeof window !== "undefined";
  const { enabledFireStore } = useSelector(selectorGlobalConfig);
  const firebaseStoreAuth =
    isBrowser() && enabledFireStore ? firebase.auth() : null;

  useEffect(() => {
    if (srcmProfile)
      window.location.href = `${window.location.origin}${localStorage.getItem(
        "LandingPage"
      )}`;
  }, [srcmProfile]);

  const handleAuth = useCallback((status) => {
    document.querySelector("hfn-oneauth")?.handleProfileAuthentication(status);
  }, []);

  useEffect(() => {
    const initializeAuthProfile = async () => {
      await import("hfn-oneauth/main");

      const authProfileElement = document.querySelector("hfn-oneauth");
      if (authProfileElement) {
        authProfileElement.loginCallback = async (response) => {
          try {
            const user = response?.data;
            if (!user?.access_token) return false;
            apiCallCount.current += 1;
            if (apiCallCount.current < 4) {
              const newsrcmprofile = await fetchSrcmProfileMe(
                user?.access_token
              );
              if (newsrcmprofile) {
                await fetchSrcmAPI(
                  "get-token",
                  user?.access_token,
                  "GET",
                  {},
                  {},
                  true,
                  {},
                  "firestoreClient"
                )
                  .then(async (res) => {
                    await firebaseStoreAuth
                      .signInWithCustomToken(res)
                      .then(() => {
                        // console.log("2nd Firebase Response", r);
                      })
                      .catch(() => {
                        dispatch(
                          showAlert({ title: "Error FirebaseStoreApp" })
                        );
                      });
                  })
                  .catch(() => {
                    dispatch(showAlert({ title: "Error FirebaseStoreApp" }));
                  });
                dispatch(
                  setUser(
                    fget(newsrcmprofile, newsrcmprofile?.user_firebase_uid)
                  )
                );
                const d = new Date();
                d.setHours(d.getHours() + 8);
                const ts = d.valueOf();
                dispatch(
                  setUserToken({
                    token: user?.access_token,
                    fbName: newsrcmprofile.name,
                    user: newsrcmprofile.email,
                    uid: newsrcmprofile.user_firebase_uid,
                    ts,
                    type: "fb",
                  })
                );
                handleAuth(true);
                window.location.href = `${
                  window.location.origin
                }${localStorage.getItem("LandingPage")}`;
                // navigate(localStorage.getItem("LandingPage"), {
                //   state: { from: location.pathname },
                // }).then(() => {
                //   window.location.reload();
                // });
              } else {
                handleAuth(false);
              }
            }
          } catch (error) {
            handleAuth(false);
          }
          return true;
        };
      }
    };

    initializeAuthProfile();
  }, [dispatch, firebaseStoreAuth, handleAuth, location.pathname]);
  return <div />;
};

export default OneAuthPage;
